html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Noto Sans KR', sans-serif;
}

.animatedArrow {
    position: relative;
    z-index: 1000;
    animation-duration: 2s;
    animation-name: toandfro;
    animation-iteration-count: infinite;
}

@keyframes toandfro {
    from {
        left: 0px;
    }
    5% {
        left: 5px;
    }
    10% {
        left: 10px;
    }
    15% {
        left: 15px;
    }
    20% {
        left: 20px;
    }
    25% {
        left: 25px;
    }
    30% {
        left: 30px;
    }
    35% {
        left: 35px;
    }
    40% {
        left: 40px;
    }
    45% {
        left: 45px;
    }
    50% {
        left: 50px;
    }
    55% {
        left: 45px;
    }
    60% {
        left: 40px;
    }
    70% {
        left: 35px;
    }
    75% {
        left: 30px;
    }
    80% {
        left: 25px;
    }
    85% {
        left: 20px;
    }
    90% {
        left: 15px;
    }
    95% {
        left: 10px;
    }
    100% {
        left: 5px;
    }
    to {
        left: 0px;
    }
}

/* //This is not a problem */

.Desktop-Container {
    display: block;
}

.Mobile-Container {
    display: none;
}

@media all and (max-width:1000px) {
    .video3 {
        /* border: 1px solid green !important; */
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
        margin-top: -45px;
        margin-bottom: -10px;
        overflow-x: hidden !important;
    }
    .video4 {
        /* border: 1px solid green !important; */
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
        margin-top: -40px;
        margin-bottom: 5px;
        overflow-x: hidden !important;
    }
    .artist1, .artist2, .artist3, .artist4, .meaning1, .meaning2, .philosophy1, .philosophy2, .philosophy3, .beauty1, .beauty2, .beauty3, .beauty4, .metal1, .metal2, .metal3, .commitment1, .commitment2, .commitment3, .commitment4 {
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
        margin-bottom: 10px;
        overflow-x: hidden !important;
    }
    .video1 {
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
        overflow-x: hidden !important;
        margin-bottom: -4px;
        margin-top: 0px;
    }
    .video2 {
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
        overflow-x: hidden !important;
        margin-top: -45px;
        margin-bottom: -45px;
    }
    .vision1 {
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
        overflow-x: hidden !important;
        margin-bottom: -20px;
        margin-top: 20px;
    }
    .video5 {
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
        overflow-x: hidden !important;
        margin-bottom: -20px;
        margin-top: -20px;
    }
    .shrink {
    object-fit: fill !important;
    }
    .vision2, .vision3, .vision4 {
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
        margin-bottom: 30px;
        overflow-x: hidden !important;        
    }
    .Desktop-Container {
        display: none;
    }
    .Mobile-Container {
        display: block;
    }
}
/* @media all and (max-width:600px){
      .vision-title {
        font-size: 45px !important;
    }
} */
@media all and (max-width:350px) {
    .vision-title {
        font-size: 35px !important;
    }
    .commitment-page-title {
        font-size: 37px !important;
    }
    /* .commit-title{
        font-size: 35px !important;
        margin-top: -200px !important;
        border: 2px solid blue !important;
    } */
}

@media all and (max-width:310px) {
    .commitment-page-title {
        font-size: 33px !important;
    }
}

.Jumbo {
    /* border: 10px solid white !important; */
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 0px !important;
    margin-top: -33px;
}

.overlay {
    height: 300px;
    position: absolute;
    width: 600px;
    -webkit-transform: translateY(-370px);
    -ms-transform: translateY(-370px);
    transform: translateY(-270px);

     @media (max-width: 1000px) {
      width: 85%;
    }
}

@media (max-width:1000px) {
    .overlay {
        width: 85%;
    }
}